<template>
  <v-pagination
    outlined
    :style="`background: ${$theme.background};`"
    :color="$theme.primary"
    total-visible="7"
    v-model="get_permissoes_filtros.page"
    :length="get_permissoes.totalPages"
    @input="setPage($event)"
  ></v-pagination>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_permissoes", "get_permissoes_filtros"])
  },
  methods: {
    ...mapActions(["listar_permissoes"]),
    setPage(page) {
      if (page) {
        console.log("page", page);
        this.get_permissoes_filtros.page = page;
        this.listar_permissoes();
      }
    }
  }
};
</script>
