<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="get_modal_view_permissao"
  >
    <v-card color="#333;" class="expande-horizontal wrap">
      <div class="expande-horizontal px-1 pr-3 py-3">
        <v-btn dark @click="fecha_modal_view_permissao" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span
          style="font-size: 12pt; width: 100%;"
          class="fonte font-weight-bold mt-1"
        >
          Perfil de Permissão
        </span>
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-3" icon @click="fecha_modal_view_permissao">
          <v-icon color="red">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pb-0 fonte">
        <v-flex xs12>
          <v-form ref="form">
            <v-flex class="px-7" xs12>
              <!-- <span
                class="py-3 fonte font-weight-bold"
                :style="`color: ${$theme.secondary}`"
              >
                Nome do perfil
              </span> -->
              <v-text-field
                v-model="get_permissao.nome"
                dense
                flat
                label="Nome do perfil"
                placeholder="Ex: Diretor"
                clearable
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
              ></v-text-field>
            </v-flex>
            <v-flex class="px-7" xs12>
              <v-select
                v-model="get_permissao.rota_inicial"
                dense
                flat
                label="Rota Inicial"
                placeholder="Ex: Diretor"
                item-text="nome"
                item-value="rota"
                clearable
                :items="[
                  {
                    nome: 'Dashboard',
                    rota: '/dashboard'
                  },
                  // {
                  //   nome: 'Menu Rocket -> Categorias e Produtos',
                  //   rota: '/menuonline'
                  // },
                  {
                    nome: 'Produtos e Estoque -> Estoque e Adicionais',
                    rota: '/insumos'
                  },
                  {
                    nome: 'Ponto de Venda (PDV)',
                    rota: '/atendimento'
                  },
                  {
                    nome: 'Entregas',
                    rota: '/entregas'
                  },
                  {
                    nome: 'Gestor de Pedido',
                    rota: '/gestordepedidos'
                  },
                  {
                    nome: 'Gestão -> Meus Clientes',
                    rota: '/clientes'
                  },
                  {
                    nome: 'Gestão -> Meus Colaboradores',
                    rota: '/funcionarios'
                  },
                  {
                    nome: 'Gestão -> Contas',
                    rota: '/invoices'
                  },
                  {
                    nome: 'Relatórios -> Geral',
                    rota: '/transacoes'
                  },
                  {
                    nome: 'Configurações -> Perfis de Permissão',
                    rota: '/permissoes'
                  },
                  {
                    nome: 'Configurações -> Configurar Empresa',
                    rota: '/configurar-empresa'
                  }
                ]"
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <v-timeline align-top dense>
                <v-timeline-item
                  fill-dot
                  :color="$theme.secondary"
                  class="pr-3"
                  icon="mdi-chart-box-outline"
                >
                  <v-card light class="expande-horizontal wrap elevation-0">
                    <v-card-title>
                      <h5 :style="`color: ${$theme.secondary}`" class="fonte">
                        Dashboard
                      </h5>
                    </v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item>
                          <v-switch
                            v-model="get_permissao.permission['dashboard']"
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title> Dashboard </v-list-item-title>
                            <v-list-item-subtitle>
                              O usuário terá acesso a tela de dashboard
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
                <v-timeline-item
                  fill-dot
                  :color="$theme.secondary"
                  class="pr-3"
                  icon="mdi-library-outline"
                >
                  <v-card light class="expande-horizontal wrap elevation-0">
                    <v-card-title>
                      <h5 :style="`color: ${$theme.secondary}`" class="fonte">
                        Produtos e Estoque
                      </h5>
                    </v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item>
                          <v-switch
                            v-model="get_permissao.permission['menu-rocket']"
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title> Produtos e Estoque </v-list-item-title>
                            <v-list-item-subtitle>
                              O usuário verá o item Produtos e Estoque do menu
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <!-- <v-list-item>
                          <v-switch
                            v-model="
                              get_permissao.permission['categorias-e-produtos']
                            "
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title>
                              Categorias e Produtos
                            </v-list-item-title>
                            <v-list-item-subtitle> </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item> -->
                        <v-list-item>
                          <v-switch
                            v-model="get_permissao.permission['meus-insumos']"
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title>
                              Estoque e Adicionais
                            </v-list-item-title>
                            <v-list-item-subtitle> </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>

                <v-timeline-item
                  fill-dot
                  :color="$theme.secondary"
                  class="pr-3"
                  icon="mdi-cash-register"
                >
                  <v-card light class="expande-horizontal wrap elevation-0">
                    <v-card-title>
                      <h5 :style="`color: ${$theme.secondary}`" class="fonte">
                        Ponto de Venda
                      </h5>
                    </v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item>
                          <v-switch
                            v-model="get_permissao.permission['ponto-de-venda']"
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title>
                              Ponto de Venda (PDV)
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              O usuário terá acesso ao PDV
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>

                <v-timeline-item
                  fill-dot
                  :color="$theme.secondary"
                  class="pr-3"
                  icon="mdi-monitor-dashboard"
                >
                  <v-card light class="expande-horizontal wrap elevation-0">
                    <v-card-title>
                      <h5 :style="`color: ${$theme.secondary}`" class="fonte">
                        Gestor de Pedidos
                      </h5>
                    </v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item>
                          <v-switch
                            v-model="
                              get_permissao.permission['gestor-de-pedidos']
                            "
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title>
                              Gestor de Compras
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              O usuário terá acesso ao Gestor de Compras
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>

                <v-timeline-item
                  fill-dot
                  :color="$theme.secondary"
                  class="pr-3"
                  icon="mdi-storefront-outline"
                >
                  <v-card light class="expande-horizontal wrap elevation-0">
                    <v-card-title>
                      <h5 :style="`color: ${$theme.secondary}`" class="fonte">
                        Gestão
                      </h5>
                    </v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item>
                          <v-switch
                            v-model="get_permissao.permission['gestao']"
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title>
                              Gestão
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              O usuário verá o menu de gestão!
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-switch
                            v-model="get_permissao.permission['meus-clientes']"
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title>
                              Meus Clientes
                            </v-list-item-title>
                            <v-list-item-subtitle> </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-switch
                            v-model="
                              get_permissao.permission['meus-colaboradores']
                            "
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title>
                              Meus Colaboradores
                            </v-list-item-title>
                            <v-list-item-subtitle> </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-switch
                            v-model="get_permissao.permission['contas']"
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title>
                              Contas
                            </v-list-item-title>
                            <v-list-item-subtitle> </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>

                <v-timeline-item
                  fill-dot
                  :color="$theme.secondary"
                  class="pr-3"
                  icon="mdi-storefront-outline"
                >
                  <v-card light class="expande-horizontal wrap elevation-0">
                    <v-card-title>
                      <h5 :style="`color: ${$theme.secondary}`" class="fonte">
                        Relatórios
                      </h5>
                    </v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item>
                          <v-switch
                            v-model="get_permissao.permission['relatorios']"
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title>
                              Relatórios
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              O usuário verá o menu de Relatórios!
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-switch
                            v-model="get_permissao.permission['geral']"
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title>
                              Geral
                            </v-list-item-title>
                            <v-list-item-subtitle> </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>

                <v-timeline-item
                  fill-dot
                  :color="$theme.secondary"
                  class="pr-3"
                  icon="mdi-storefront-outline"
                >
                  <v-card light class="expande-horizontal wrap elevation-0">
                    <v-card-title>
                      <h5 :style="`color: ${$theme.secondary}`" class="fonte">
                        Configurações
                      </h5>
                    </v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item>
                          <v-switch
                            v-model="get_permissao.permission['configuracoes']"
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title>
                              Configurações
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              O usuário verá o menu de Configurações!
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-switch
                            v-model="
                              get_permissao.permission['perfis-de-permissao']
                            "
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title>
                              Perfis de Permissão
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              O usuário poderá alterar as permissões!
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-switch
                            v-model="
                              get_permissao.permission['configurar-empresa']
                            "
                          ></v-switch>
                          <v-list-item-content>
                            <v-list-item-title>
                              Configurar Empresa
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              O usuário poderá alterar as configurações da
                              empresa!
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-flex>
          </v-form>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="valida_form" tile block color="green">
          <span class="fonte"> Salvar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "get_permissao",
      "get_modal_view_permissao",
      "getLoggedUser"
    ])
  },
  methods: {
    ...mapActions([
      "criar_permissao",
      "atualizar_permissao",
      "fecha_modal_view_permissao"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_permissao._id
          ? this.atualizar_permissao()
          : this.criar_permissao();
      }
    }
  }
};
</script>
